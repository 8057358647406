export const getStrapiContact = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`
    }
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        contactTitle: attributes?.contact_title,
        contactSubtitle: attributes?.contact_subtitle,
        contactSubtitleDown: attributes?.contact_subtitle_down,
        contactAddress: attributes?.contact_address,
        contactNumber: attributes?.contact_number,
        contactEmail: attributes?.contact_email,
        contactMap: attributes?.contact_map
      }
    }
  )

  return values
}
