export const getStrapiGallery = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`
    }
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        galleryTitle: attributes?.gallery_title,
        galleryServiceTitle: attributes?.gallery_service,
        galleryImages: attributes?.gallery_images?.data?.map(({id, attributes}) => ({
          id: id,
          image: process.env.REACT_APP_STRAPI_URL + attributes?.url
        }))
      }
    }
  )

  return values
}
