const MapGoogle = ({ contactMap, contactAddress }) => {
  return (
    <div className="w-full h-[20%] mb-[3rem]">
      <iframe
        className="w-full"
        title={contactAddress}
        src={contactMap}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      >
      </iframe>
    </div>
  )
}

export default MapGoogle
